import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { useFlexSearch } from "react-use-flexsearch"
import * as FaIcons from "react-icons/fa"

import Seo from "../components/seo"

const GET_SEARCH_CONTENT = graphql`
  {
    localSearchTexts {
      index
      store
    }
    localSearchPages {
      index
      store
    }
  }
`

const ArticleResult = ({ data }) => {
  const { id, path, title, excerpt, body, type, outsideLink } = data

  const isOutside = type == "Outside_Article"
  return (
    <div className="search-card">
      <h3>{title}</h3>
      <div
        className="excerpt"
        dangerouslySetInnerHTML={{
          __html: isOutside
            ? excerpt.substring(0, 350).concat("....")
            : body.substring(0, 350).concat("...."),
        }}
      />
      <Link
        to={isOutside ? outsideLink.url : `/news/${path}`}
        target={isOutside ? "_blank" : ""}
        className="btn btn-blue"
      >
        Read More
      </Link>
    </div>
  )
}
const PageResult = ({ data }) => {
  const { title, path, body } = data
  return (
    <div className="search-card">
      <h3>{title}</h3>
      <div
        className="excerpt"
        dangerouslySetInnerHTML={{
          __html: body.substring(0, 350).concat("...."),
        }}
      />
      <Link to={path === "home" ? "/" : `/${path}`} className="btn btn-blue">
        Read More
      </Link>
    </div>
  )
}

const SearchResultsPage = ({ location }) => {
  const searchTerm = location.state ? location.state.query : ""
  const data = useStaticQuery(GET_SEARCH_CONTENT)
  let postResults = useFlexSearch(
    searchTerm,
    data.localSearchPages.index,
    data.localSearchPages.store
  )
  let pageResults = useFlexSearch(
    searchTerm,
    data.localSearchTexts.index,
    data.localSearchTexts.store,
    {
      suggest: true,
      tokenize: "full",
    }
  )

  return (
    <section id="search-results">
      <Seo title="Search The Site" />
      <div className="container">
        <h1>
          Search results for:
          <span>
            {location.state
              ? `"${location.state.query}"`
              : `"search term not found"`}
          </span>
        </h1>
        {/* {postResults.length <= 0 && pageResults.length <= 0 ? ( */}
        {pageResults.length <= 0 ? (
          <div className="no-results">
            <h2>Sorry, we couldn't find what you were looking for.</h2>
            <FaIcons.FaFrown id="no-results-icon" />
          </div>
        ) : (
          <div className="display-grid">
            {pageResults.length > 0 && (
              <div>
                <div className="page-grid">
                  <h2>Pages: </h2>
                  {pageResults.map(item => {
                    return <PageResult data={item} />
                  })}
                </div>
              </div>
            )}

            {postResults.length > 0 && (
              <>
                <div className="results-grid">
                  <h2>News Posts: </h2>
                  {postResults.map(item => {
                    return <ArticleResult data={item} />
                  })}
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </section>
  )
}

export default SearchResultsPage
